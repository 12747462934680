import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Home.module.scss';
import * as getProductCategoriesForClient from '../../../graphql/getProductCategoriesForClient';
import * as getArticles from '../../../graphql/getArticles';
import * as getAllProductCategories from '../../../graphql/getAllProductCategories';
import * as getClient from '../../../graphql/getClient';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'src/components/system/Button/Button';
import Loading from 'src/components/system/Loading/Loading';
import InlineAlert, { InlineAlertType } from 'src/components/system/InlineAlert/InlineAlert';
import { getConfig } from 'src/config';
import { FaArrowRight } from 'react-icons/fa';
import ProductCategoriesSlider from './Components/ProductCategoriesSlider';
import OVNSlider from './Components/OVNSlider';
import AboutGfkCards from './Components/AboutGfkCards';

import { queryStringKeys } from '../../ReleaseTable/ReleaseTable';
import AboutGfkModal from './Components/AboutGfkModal';
const Home: React.FC = () => {

  const [clientAccountGroupKey, setClientAccountGroupKey] = useState<number>();
  const [productCategories, setProductCategories] = useState<{id: number, name: string}[]>([]);
  const [articles, setArticles] = useState<any[]>([]);
  const [allProductCategories, setAllProductCategories] = useState<{id: number, name: string}[]>([]);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState<boolean>(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false);

  const hasClientQueryCalled = useRef<boolean>(false);

  const config = getConfig();

  const [clientQuery, clientQueryData] =
  useLazyQuery<getClient.ResultType>(getClient.query, {
    onCompleted: (data) => {
      const clientAGK = data?.client?.account?.accountGroupKey;
      setClientAccountGroupKey(clientAGK);
    },
  });

  const [productCategoriesQuery, productCategoriesQueryData] =
  useLazyQuery<getProductCategoriesForClient.ResultType>(getProductCategoriesForClient.query, {
    onCompleted: (data) => {
      const productCategoriesList = data?.productCategoriesForClient?.map(({ id, name }) =>
      ({ id, name }));
      setProductCategories(productCategoriesList);
    },
  });

  const [articlesQuery, articlesQueryData] =
  useLazyQuery<getArticles.ResultType>(getArticles.query, { variables: { take: null, isHome: true } ,
    onCompleted: (data) => {
      const articlesList = data?.articles?.items;
      setArticles(articlesList);
    },
  });

  const [allProductCategoriesQuery, allProductCategoriesQueryData] =
  useLazyQuery<getAllProductCategories.ResultType>(getAllProductCategories.query, {
    onCompleted: (data) => {
      const productCategoriesList = data?.allProductCategories
      .filter(category => category.active)
      .filter(category => category.accountGroupKey === clientAccountGroupKey)
      .map(({ id, name }) => ({ id, name }));
      setAllProductCategories(productCategoriesList);
    },
  });

  useEffect(() => {
    articlesQuery();
    if (!hasClientQueryCalled.current) {
      hasClientQueryCalled.current = true;
      clientQuery();
    } else {
      productCategoriesQuery();
      allProductCategoriesQuery();
    }
    // eslint-disable-next-line
  },        [clientAccountGroupKey]);

  const history = useHistory();
  const productCategoryRedirect = (id: number) => {
    const path = `/releases?${queryStringKeys.productCategoryKey}=${id}`;
    history.push(path);
  };

  const sectorRedirect = (id: number) => {
    const path = `/news/${id}`;
    history.push(path);
  };

  const newsRedirect = () => {
    const path = '/news';
    history.push(path);
  };

  const toggleAboutModal = () => {
    setIsAboutModalOpen(!isAboutModalOpen);
  };

  const toggleCategoryModal = () => {
    setIsCategoryModalOpen(!isCategoryModalOpen);
  };

  const categoryTileArray: {id: number, name: string, accessible: boolean}[] = [];
  allProductCategories.forEach((category) => {
    if (productCategories.some(pc => pc.id === category.id)) {
      categoryTileArray.push({ id: category.id, name: category.name, accessible: true });
    } else {
      categoryTileArray.push({ id: category.id, name: category.name, accessible: false });
    }
  });

  const sortedCategories = [...categoryTileArray];
  sortedCategories.sort((a, b) => Number(b.accessible) - Number(a.accessible));

  const prodCatSliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: allProductCategories.length < 5 ? allProductCategories.length : 5,
    slidesToScroll: 4,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: allProductCategories.length < 4 ? allProductCategories.length : 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: allProductCategories.length < 3 ? allProductCategories.length : 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: allProductCategories.length < 2 ? allProductCategories.length : 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: allProductCategories.length < 1 ? allProductCategories.length : 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ovnSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    draggable: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {(() => {
        if (clientQueryData.loading || productCategoriesQueryData.loading
            || allProductCategoriesQueryData.loading || articlesQueryData.loading) {
          return <Loading />;
        }

        if (productCategoriesQueryData.error || allProductCategoriesQueryData.error || clientQueryData.error) {
          return (
            <InlineAlert type={InlineAlertType.DataError}>
              <p>Unable to retrieve product categories from server</p>
            </InlineAlert>
          );
        }

        return (
          <>
            <div className={styles.main_img}>
              <img
                alt="GfK Boutique"
                src={`https://${config.s3BucketName}/gfklanding.webp`}
              />
            </div>
            <ProductCategoriesSlider
              sortedCategories={sortedCategories}
              onCategoryClick={productCategoryRedirect}
              sliderSettings={prodCatSliderSettings}
              onCategoryNotSubscribedClick={toggleCategoryModal}
            />
            <div className={styles.ovnHeader}>
              <h1>{`OVN Digest | ${new Date(articlesQueryData.data?.articles.items[0].publishedAt).toLocaleString('en-US', { month: 'short', day: 'numeric' })}`}</h1>
              <h5 className={styles.moreNews} onClick={newsRedirect}>More News<FaArrowRight className={styles.newsArrow}/></h5>
            </div>
            <OVNSlider
              articles={articles}
              ovnSettings={ovnSettings}
              onSectorRedirect={sectorRedirect}
            />
            <br />
            <AboutGfkCards onMoreButtonClick={toggleAboutModal}/>
            <AboutGfkModal isOpen={isAboutModalOpen} toggle={toggleAboutModal} />
            <Modal isOpen={isCategoryModalOpen} toggle={toggleCategoryModal} className={styles.modal}>
              <ModalBody>
                <div className={styles.modal_item}>
                  <h1>Product Categories</h1>
                  <p>
                    You are not currently subscribed to this product category. To enquire about subscriptions please contact <a href="mailto:Boutique-ClientSuccessTeam@gfk.com">Boutique-ClientSuccessTeam@gfk.com</a>
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={toggleCategoryModal}>Close</Button>
              </ModalFooter>
            </Modal>
          </>
        );
      })()}
    </>
  );
};
export default Home;
