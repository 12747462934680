import React from 'react';
import Slider from 'react-slick';
import { Card, CardBody, CardLink, CardText, CardTitle, Badge } from 'reactstrap';
import styles from './OVNSlider.module.scss';

type OVNSliderProps = {
  articles: any[];
  ovnSettings: any;
  onSectorRedirect: (id: number) => void;
};

const OVNSlider: React.FC<OVNSliderProps> = ({ articles, ovnSettings, onSectorRedirect }) => {
  return (
    <Slider {...ovnSettings} className={styles.ovnCarousel}>
      {articles?.map(article => (
        <div key={article.id}>
          <Card className={`my-2 ${styles.fillGrid}`}>
            <CardBody style={{ padding: '1em' }}>
              <CardTitle className={styles.title}>
                {article.brands?.map((brand: { name: string; }) => brand.name).join(' | ')}
              </CardTitle>
              <small className={`${styles.topright} text-muted`}>
                {new Date(article.publishedAt).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
              </small>
              <CardText style={{ height: '155px', overflowY: 'auto', display: 'block' }}>
                <small className="text-muted">
                  {article.body}
                </small>
              </CardText>
              <div className={styles.pill}>
                {(article.country && article.country.name !== 'UNCATEGORISED') &&
                  <Badge className={styles.badge} pill key={article.country.id}>{article.country.name}</Badge>}
                <Badge className={styles.badge} pill key={article.sector.id}
                  onClick={() => onSectorRedirect(article.sector.id)}>{article.sector.name}</Badge>
                {article.source && <CardLink href={article.source} target="_blank">Source</CardLink>}
                {article.source2 && <CardLink href={article.source2} target="_blank">Source 2</CardLink>}
              </div>
            </CardBody>
          </Card>
        </div>
      ))}
    </Slider>
  );
};

export default OVNSlider;
