import React, { useEffect } from 'react';
import styles from './WorldBanner.module.scss';
import { getConfig } from 'src/config';
import LayoutWrapper from 'src/components/system/LayoutWrapper/LayoutWrapper';

const WorldBanner: React.FC = () => {
  const config = getConfig();

  useEffect(() => {
    const mainImgDiv = document.querySelector('#mainImageDiv');
    
    if (mainImgDiv) {
      window.addEventListener('scroll', () => {
        const scrollPosition = window.scrollY;
        const maxScroll = 420; // Maximum scroll value (Image Height)
        const startPosition = -300; // Starting position
        const endPosition = 0; // End position when scroll reaches maxScroll
        const newPositionY = startPosition + (endPosition - startPosition) * (scrollPosition / maxScroll);

        // Ensure the element is an HTMLElement before setting the style
        if (mainImgDiv instanceof HTMLElement) {
          mainImgDiv.style.backgroundPositionY = `${Math.min(newPositionY, endPosition)}px`;
        }
      });
    }

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', () => {
        if (mainImgDiv instanceof HTMLElement) {
          mainImgDiv.style.backgroundPositionY = '';
        }
      });
    };
  }, []);
  
  return (
    <div id="mainImageDiv" className={styles.main_img} 
      style={{ 
        backgroundImage: `url(https://${config.s3BucketName}/GFK_World.webp)`,
        backgroundPositionX: 'center',
        backgroundPositionY: '-300px',
        backgroundSize: '2304px 720px',
        backgroundRepeat: 'no-repeat' 
      }}
    >
      <div className={styles.overlay}>
        <LayoutWrapper>
          <h1 className={styles.investorUpdate}>GfK Boutique Research</h1>
          <p className={styles.updateText}>
            Strengthen your financial decision-making with the world's
            <br/>most comprehensive point-of-sale data.
          </p>
        </LayoutWrapper>
      </div>
    </div>
  );
};

export default WorldBanner;
