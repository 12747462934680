import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './Icon.module.scss';

export interface IconDef {
  width: string;
  height: string;
  viewBox: string;
  style: { width?: string; height?: string };
}

interface Props {
  icon: React.FC<IconDef>;
  className?: string;
  color?: string;
  hasHover?: boolean;
  size?: string;
}

const Icon: React.FC<Props> = (props) => {
  const [color, setColor] = useState<string>(props.color || 'bright-orange');

  useEffect(() => {
    if (!props.hasHover && props.color) {
      setColor(props.color);
    }
  }, [props.hasHover, props.color]);

  const hoverColors = {
    'bright-orange': 'dark-orange',
    'mid-grey': 'dark-grey',
    'light-blue': 'dark-blue',
  };
  // @ts-ignore FIX-ME:
  const hoverColor = hoverColors[color] || color;
  const Component = props.icon;

  const className = classnames(
    styles.Icon,
    props.className,
    props.icon.name ? props.icon.name.replace('Svg', 'Icon__') : null,
    styles[`Icon__${color}`],
  );

  return (
    <i
      className={className}
      onMouseEnter={() => {
        if (props.hasHover) {
          setColor(hoverColor);
        }
      }}
      onMouseLeave={() => setColor(props.color || 'bright-orange')}
    >
      <Component width="24" height="24" viewBox="0 0 24 24" style={ { width: props.size, height: props.size }} />
    </i>
  );
};

export default Icon;
