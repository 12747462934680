import React from 'react';
import Slider from 'react-slick';
import styles from './ProductCategoriesSlider.module.scss';

type ProductCategoriesSliderProps = {
  sortedCategories: {id: number, name: string, accessible: boolean}[];
  onCategoryClick: (id: number) => void;
  sliderSettings: any;
  onCategoryNotSubscribedClick: () => void;

};

const ProductCategoriesSlider: React.FC<ProductCategoriesSliderProps> = (
  {
    sortedCategories,
    onCategoryClick,
    sliderSettings,
    onCategoryNotSubscribedClick,
  }) => {
  return (
    <Slider {...sliderSettings} className={styles.carousel}>
      {
        sortedCategories.filter(category => category.accessible).map(category => (
          <div key={category.id} onClick={() => onCategoryClick(category.id)} className={styles.carousel_item}>
            <h3>{category.name}</h3>
          </div>
        ))
      }
      {
        sortedCategories.filter(category => !category.accessible).map(category => (
          <div key={category.id} onClick={onCategoryNotSubscribedClick} className={styles.carousel_item_no_sub}>
            <h3>{category.name}</h3>
          </div>
        ))
      }
    </Slider>
  );
};

export default ProductCategoriesSlider;
